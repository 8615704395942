/* src/App.css */
nav {
  background-color: #fff;
  padding: 10px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

li {
  margin-right: 20px;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
body{
  overflow: hidden;
  /* height: 1000px; */
  font-family: Arial, Helvetica, sans-serif;
  /* background-image: url('/src/images/CamEd_Building.jpg');
  background-repeat: repeat; */
}
.calendar_default_colheader_inner{
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  background: #76323f;
  border-top: 1px solid #a29a9a;
  border-bottom: 1px solid #a29a9a;
  padding-top: -2px;
}
.calendar_default_corner_inner{
  background: #76323f;
  border-top: 1px solid #a29a9a;
  border-bottom: 1px solid #a29a9a;
}
.time-note {
  background-color: #efefef;
  color: #000;
  box-shadow: 3px 3px 3px #a1a0a0 inset;
  padding: 0px 0px 10px 10px;
}
.time-note fieldset{
  border: 1px solid #999;  
  /* color: #f00; */
  height: auto;
  margin: 0px;
  padding: 3px;
  font-size: 14px;
  width: 220px;
}
.time-note legend{
  font-size: 14px;
  padding-top: 10px;
}
.time-line{
  border-bottom: 5px solid #d6bc1d;
  float: left;
  width: 100px;
  margin: -10px 10px 10px 10px;
}
.time-line-white{
  border-bottom: 5px solid #fff;
  float: left;
  width: 100px;
  margin: -10px 10px 10px 10px;
}
.time-des{
  color: #000;
  font-size: 13px;
  margin-top: 5px;
}
table tr td img{
  width: 30px;
  height: 30px;
}

.container {
  /* display: flex; */
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20 auto;
  text-align: center;
  background: #efefef;
  padding: 5px 10px 10px 0px;
}
.container .image-wrap{
  flex-basis: calc(33.33% - 20px); /* Adjust the width and margin as needed */
  margin: 5px 0px 0px 5px;
  box-sizing: border-box;
}

.container img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  /* border: 1px solid #ccc; */
}
.container .date-block{
  float: left;
  width: 80px;
}
.container .date-block .day{
  font-size: 26px;
  font-weight: bold;
  color: #cab08f;
}
.container .date-block .month{
  font-size: 21px;
  color: #ccc;
}
.container .date-block .year{
  font-size: 22px;
  color: #76323f;
}
.container .date-block .year{
  border-bottom: 3px solid #cab08f;
  margin: 0px 15px;
}
.container .title-block{
  margin-left: 10px;
  float: left;
  flex-wrap: wrap;
  word-wrap: break-word;
  width: 240px;
  text-align: left;
  /* border: 1px solid #ccc; */
}
.container .title-block .title{
  margin-bottom: 10px;
  font-size: 16px;
  color: #000;
  font-weight: bold;
}
.container .title-block .time{
  font-size: 11px;
}
.container .title-block .location{
  font-size: 11px;
  margin-top: 8px;
}
.responsive-image-container {
  margin: 0 auto;
  margin: 0px 10px;
}
/* footer of page */
.booked-schedule{
  /* float: left; */
  margin: 35px 10px 10px 10px;
  font-size: 13px;
}
.booked-schedule .redtext{
  color: #f00;
}
.power-by{
  /* float: right; */
  padding: 0px 10px 10px 10px;
  color: #000;
  font-size: 13px;
  text-align: right;
}
.power-by .text-bold{
  font-weight: bold;
  color: #76323f;
}
/* end footer of page */

/* loading spinner */
.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #333;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 20px auto; /* Adjust margin as needed */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.hollow-dots-spinner, .hollow-dots-spinner * {
  box-sizing: border-box;
}

.hollow-dots-spinner {
  height: 15px;
  width: calc(30px * 3);
}

.hollow-dots-spinner .dot {
  width: 15px;
  height: 15px;
  margin: 0 calc(15px / 2);
  border: calc(15px / 5) solid #ff1d5e;
  border-radius: 50%;
  float: left;
  transform: scale(0);
  animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
}


/* end loading spinner */